var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[(!_vm.isRating)?_c('BackOnTop',{attrs:{"title":"消息中心","labelRight":`${_vm.currentTab == 'msg' ? '全部已讀' : ''}`,"showRight":"","url":"/"},on:{"click:right":function($event){return _vm.readInfo(-1)}}}):_vm._e(),(!_vm.$store.state.isLoading)?_c('v-container',{staticStyle:{"padding-left":"32px","padding-right":"32px","padding-top":"16px"},attrs:{"fluid":""}},[_c('div',{staticClass:"info_wrappper"},[_c('div',{staticClass:"col-12",staticStyle:{"position":"sticky","top":"50px"}},[_c('div',{staticClass:"col-12 row"},[_c('div',{class:`col-6 ${
                            _vm.currentTab == 'msg'
                                ? 'style-active-tab'
                                : 'style-inactive-tab'
                        }`,staticStyle:{"background-color":"white"},on:{"click":function($event){_vm.currentTab = 'msg';}}},[_vm._v(" 個人消息 ")]),_c('div',{class:`col-6 ${
                            _vm.currentTab == 'news'
                                ? 'style-active-tab'
                                : 'style-inactive-tab'
                        }`,staticStyle:{"background-color":"white"},on:{"click":function($event){_vm.currentTab = 'news';}}},[_vm._v(" 最新消息 ")])])]),(_vm.currentTab === 'msg')?_c('ul',{staticClass:"msg_list",staticStyle:{"overflow":"scroll","height":"90vh"}},_vm._l((_vm.msgList),function(el,idx){return _c('li',{key:idx,staticClass:"d_flex col-12",on:{"click":function($event){_vm.readInfo(el.id);
                        el.is_read = true;
                        _vm.redirectHandler(el.type, el.pageidorurl);}}},[_c('div',{staticClass:"detail f_black col-12"},[_c('div',{staticClass:"row",staticStyle:{"align-items":"center","margin-bottom":"6px"}},[(!el.is_read)?_c('div',{staticStyle:{"width":"8px","height":"8px","border-radius":"100%","background-color":"red","margin-bottom":"10px","margin-right":"8px"}}):_vm._e(),_c('p',{staticClass:"style-body-regular",staticStyle:{"font-weight":"700","color":"#0d0c0c","letter-spacing":"0.56px"}},[_vm._v(" "+_vm._s(el.title)+" ")])]),_c('p',{staticClass:"style-body-regular",staticStyle:{"color":"#6f6f6f","min-height":"30px","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(el.detail)+" ")]),_c('div',{staticClass:"row col-12",staticStyle:{"justify-content":"flex-end","position":"relative","bottom":"0px"}},[_c('div',{staticClass:"col-6 style-body-regular row",staticStyle:{"font-size":"12px","display":"flex","align-items":"center"}},[_c('div',{style:(`margin-right: 6px; height:18px;width: 18px;background-image: url(${
                                        _vm.iconMap[el.type]
                                            ? _vm.iconMap[el.type]
                                            : _vm.iconMap['system']
                                    });`)}),_c('div',{staticStyle:{"color":"#363230"}},[_vm._v(" "+_vm._s(_vm.labelMap[el.type] ? _vm.labelMap[el.type] : _vm.labelMap['system'])+" ")])]),_c('div',{staticClass:"col-6",staticStyle:{"margin-top":"3px","text-align":"right","color":"#363230","font-size":"12px"}},[_vm._v(" "+_vm._s(el.time.split(' ')[0])+" ")])])])])}),0):_c('ul',{staticClass:"news_list"},_vm._l((_vm.newsList),function(el,idx){return _c('li',{key:idx,staticClass:"d_flex"},[_c('router-link',{attrs:{"to":{
                            path: '/info/news_for_msg',
                            query: {
                                category_id: el.category_id,
                                page: el.page,
                                type: '最新消息',
                            },
                        }}},[_c('div',{staticClass:"img_wrapper"},[_c('img',{attrs:{"src":el.imgSrc,"alt":""}})]),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"style-body-regular",staticStyle:{"font-weight":"700","color":"#0d0c0c","margin-bottom":"6px"}},[_vm._v(" "+_vm._s(el.title)+" ")]),_c('p',{staticClass:"style-body-regular",staticStyle:{"color":"#6f6f6f","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(el.detail)+" ")]),_c('div',{staticClass:"col-12 row",staticStyle:{"justify-content":"flex-end"}},[_c('div',{staticClass:"col-6"},[_c('div',{staticStyle:{"padding":"4px 4px","font-size":"12px","background-color":"#19b86a","color":"white","border-radius":"4px","width":"fit-content"}},[_vm._v(" "+_vm._s(el.type)+" ")])]),_c('div',{staticClass:"col-6",staticStyle:{"margin-top":"3px","text-align":"right","color":"#0D0C0C","font-size":"12px"}},[_vm._v(" "+_vm._s(el.time)+" ")])])])])],1)}),0)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }